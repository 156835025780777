import '../asset/css/table.css';
import '../asset/css/index.css';

import { Card, DatePicker, Modal, Popover, Spin, Table } from 'antd';
import { getCommonElements, getURL } from '../util/index';

import AuditTraceTable from '../components/AuditTraceTable';
import Constant from '../util/Constant.json';
import ErrorGeos from "../components/errorSummary/ErrorGeos";
import ErrorSummaryScreenService from '../service/ErrorSummaryScreen';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import SiteService from '../service/Sites';
import js from 'jsonpath';
import jsonDiff from 'json-diff';
import logo from "../asset/images/travelex-logo-old.svg";
import moment from "moment";

const style = {
    margin: '10px'
};
class ErrorSummaryScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            selectedDate: new Date().getFullYear() + '-' + ("0" + (new Date().getMonth() + 1)).slice(-2),
            regionList: [],
            filterData: [],
            selectedFilters: { status: true },
            shouldRender: true,
            loader: <Spin spin={true} size='large' />,
            expandedKeys: [],
            changeDate: false,
            siteNameFilterRegion: null,
            auditData: null,
            columnName: null,
            columnData: null,
            isModalOpen: false,
            selectedCountry: null,
            selectedRegion: null,
            statusCode: null
        };
        localStorage.setItem('monthData', null);

    }

    statusCodesMap = {
        M: { className: "missingTile", description: "Missing" },
        E: { className: "fileProcessingTile", description: "Error in Master" },
        EL: { className: "ledgerErrorTile", description: "Error in Ledger" },
        EW: { className: "warehouseErrorTile", description: "Error in Warehouse" },
        EO: { className: "oracleErrorTile", description: "Error in Oracle" },
        ELW: { className: "warehouseLedgerErrorTile", description: "Error in Warehouse & Ledger" },
        ELO: { className: "oracleLedgerErrorTile", description: "Error in Ledger & Oracle" },
        EOW: { className: "oracleWarehouseErrorTile", description: "Error in Oracle & Warehouse" },
        ELOW: { className: "elowErrorTile", description: "Error in Oracle, Warehouse & Ledger" },
        Y: { className: "processingTile", description: "Processed" },
        IP: { className: "inProgressTile", description: "In Progress" },
        C: { className: "closedTile", description: "Closed" },
        EWCN: { className: "warehouseErrorTile", description: "Error in China" },
        EWAU: { className: "warehouseErrorTile", description: "Error in China-APAC" }
    };

    filterDataByAvailableData = async (date, filterData = {}) => {
        filterData.date = date;
        let saveData = localStorage.getItem('monthData');
        if (saveData === undefined || saveData === null) {
            localStorage.setItem('monthData', JSON.stringify(filterData));
        }
        else {
            let diff = jsonDiff.diff(JSON.parse(saveData), filterData);
            if (diff) {
                localStorage.setItem('monthData', JSON.stringify(filterData));
            }
            else {
                return false;
            }
        }
        let url = getURL(Constant.URL.GET_MONTHLY_DATA);
        if (date) {
            url.searchParams.append('date', date);
        }
        let availableData = await ErrorSummaryScreenService.getMonthData(url.href, {});
        return availableData;
    };

    changeDate = (date, dateString) => {
        this.setState({ selectedDate: dateString, changeDate: true });
        // this.props.onMonthChange(dateString);
    };

    async componentDidMount() {

        let response = JSON.parse(localStorage.getItem('sideBarData'));

        let data = response.message.regions;
        let filterData = await this.filterDataByAvailableData(this.state.selectedDate);
        this.setState({ data: data, loader: '', filterData: filterData.message.regions || [], expandedKeys: [], changeDate: false });


    }



    getCompleteText(text) {
        let completeText = "";
        if (text) {
            let splittedText = text.split("|");  //check for multiple pipe seperator
            for (let splittedTextIndex = 0; splittedTextIndex < splittedText.length; splittedTextIndex++) {

                if (splittedText[splittedTextIndex]) {
                    let delimiter = splittedText[splittedTextIndex].lastIndexOf("#");  //get the # part 

                    let substringFrom = 0; //from were we need to split the string
                    if (delimiter > 2) {
                        substringFrom = 2; //if delimiter is more than 2 then we need show the second the code 
                    } else if (delimiter === -1) {
                        delimiter = splittedText[splittedTextIndex].length; //only when no # is present
                    }

                    if (completeText) substringFrom++;  //if EL & EW both are present

                    completeText += splittedText[splittedTextIndex].substring(substringFrom, delimiter); //create completeText
                }
            }
        }
        return completeText;
    }

    setLoader() {
        this.setState({ loader: <Spin spin={true} size='large' /> });
    }

    setAuditDetails = (data) => {
        this.setState({ auditData: data });
    };

    setColumnName = (data, country, region, statusCode) => {
        // console.log("setColumnName :: ", data, " ----- ", country, " ----- ", region, " ---- ", statusCode);
        this.setState({ columnName: data.currentTarget.dataset.id < 10 ? "0" + data.currentTarget.dataset.id : data.currentTarget.dataset.id, columnData: data.target.getAttribute("name"), selectedCountry: country, selectedRegion: region, statusCode: this.getCompleteText(statusCode) });
        // {this.state.auditData ? this.showModal(this.state.auditData.key) : console.log("ddddddddddddddddddd")};
        this.showModal();

    };

    showModal = async () => {
        this.setState({ isModalOpen: true });
    };

    handleOk = () => {
        this.setState({ isModalOpen: false });
    };

    handleCancel = () => {
        this.setState({ isModalOpen: false });
    };

    async componentDidUpdate() {

        let data = await this.filterDataByAvailableData(this.state.selectedDate, this.props.filterData);
        let result = null;
        if (data) {
            let response = JSON.parse(localStorage.getItem('sideBarData'));
            if (this.props.filterData.siteName.length) {
                result = this.getCountryAndRegion(this.props.filterData.siteName, response);
            }
            if (this.state.changeDate) {
                this.setState({ filterData: data.message.regions || [], loader: '', changeDate: false, siteNameFilterRegion: result });
            }
            else {
                this.setState({ filterData: data.message.regions || [], loader: '', expandedKeys: [], siteNameFilterRegion: result });
            }
        }
    }

    getCountryAndRegion(selectedSiteName, response) {
        let countries = this.searchSiteName(selectedSiteName, response);
        let region = response.message.regions;
        let regionList = [];
        countries.forEach(country => {
            region.forEach(region => {
                if (region.countries.includes(country)) {
                    if (!regionList.includes(country))
                        regionList.push(region.name);
                }
            });
        });

        return { countries, regionList };
    }

    searchSiteName(selectedSiteName, response) {
        let countries = [];
        selectedSiteName.forEach(element => {
            for (const [key, value] of Object.entries(response.message)) {
                if (key !== 'regions') {
                    let search = [...js.query(value, `$.sites.*[?(@.SiteName == "${element}")]`)];
                    if (search.length) {
                        if (!countries.includes(key))
                            countries.push(key);
                    }
                }
            }
        });
        return countries;
    }

    disabledDate = (current) => {

        if (moment().year() < current.year()) {
            return true;
        }
        else if (moment().year() === current.year() && current.month() > moment().month()) {
            return true;
        }
        else {
            return false;
        }
    };


    statusInfoModal = () => {
        const getClassName = (dayFlag) => {
            if (dayFlag.startsWith("C")) return "closedTile";
            return this.statusCodesMap[dayFlag]?.className || "";
        };

        const getStatusMessage = (status) => {
            return this.statusCodesMap[status] ? `${status} | ${this.statusCodesMap[status].description}` : "";
        };


        return (
            <Modal title={<img
                alt=""
                src={logo}
                width="160"
                height="50"
                className="d-inline-block align-top"
            />}
                style={{ top: "1%" }}
                open={this.state.isModalOpen}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={"80%"}
                bodyStyle={{ height: 450 }}
                footer={null}
            >
                <div style={{ height: "100%" }}>
                    <div className='audit-logs-info'>
                        <div style={{ float: "left", width: "50%", paddingLeft: "2px" }}>
                            {/* <b>Site ID : </b>{this.state.auditData ? (this.state.auditData.key): ("")}<br /> */}
                            <b>Site Name : </b>{this.state.auditData ? (this.state.auditData.name) : ("")}<br />
                            <b>File Name : </b>DES{this.state.auditData ? (this.state.auditData.key) : ("")}PRD{moment(this.state.selectedDate + "-" + this.state.columnName).dayOfYear()}YR{moment(this.state.selectedDate + "-" + this.state.columnName).format("YY")}.DE<br />
                            <b>Status Message : </b><span className={this.state.statusCode ? (getClassName(this.state.statusCode)) : ("")} style={{ paddingLeft: "5px", paddingRight: "5px" }}>{this.state.statusCode ? getStatusMessage(this.state.statusCode) : ("")}</span>
                        </div>
                        <div style={{ float: "left", width: "50%" }}>
                            <b>Date : </b>{this.state.selectedDate + "-" + this.state.columnName}<br />
                            <b>Region : </b>{this.state.selectedRegion}<br />
                            <b>Country : </b>{this.state.selectedCountry}
                        </div>
                    </div>
                    <div>
                        {this.state.auditData && this.state.columnName ? (
                            <AuditTraceTable country={this.state.selectedCountry} date={this.state.selectedDate + "-" + this.state.columnName} siteId={this.state.auditData.key} />
                        ) :
                            ("")
                        }
                    </div>
                </div>


            </Modal>
        );
    };

    cardTitle = () => {
        return (
            <div className="">
                <div style={{ float: "left" }}>Stores</div>
                <div style={{ float: "right" }}>
                    <DatePicker defaultValue={moment()}
                        disabledDate={this.disabledDate}
                        onChange={(date, dateString) => this.changeDate(date, dateString)} picker="month" />

                    <Popover
                        content={(
                            <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
                                {
                                    Object.entries(this.statusCodesMap).map(([code, { className, description }]) =>
                                        <p key={code} className={`${className} f16 mb1`}>
                                            <span style={style}>{`${code} | ${description}`}</span>
                                        </p>
                                    )
                                }
                            </div>

                        )}
                        title={<h6>Status Code Sheet</h6>}
                        trigger="hover"
                    >
                        <span> <InfoCircleOutlined /></span>
                    </Popover>
                </div>
            </div>
        );
    };



    render() {
        const columns = [
            { title: 'Region' }
        ];
        let data = this.state.data;
        let regionData = [];
        const { filterData } = this.props;
        const { region, country, siteName } = filterData;

        if (region?.length)
            regionData = [...region];
        else if (country?.length) {
            data.map(d => {
                country.forEach(c => {
                    if (d.countries.includes(c) && !regionData.includes(d.name))
                        regionData.push(d.name);
                });

            });
        }
        else if (siteName?.length && this.state.siteNameFilterRegion)
            data.map(d => {
                if (this.state.siteNameFilterRegion.regionList.includes(d.name))
                    regionData.push(d.name);
            });
        else
            data.map(d => regionData.push(d.name));

        let countries = [];
        this.state.filterData.map(d => {
            countries = [...countries, ...d.countries];
            return false;
        });

        return (
            <React.Fragment>

                {/* Modal Section */}
                {
                    this.statusInfoModal()
                }
                <Card title={this.cardTitle()}>
                    {
                        this.state.loader ? <div style={{ textAlign: "center" }}>{this.state.loader} </div> :
                            <Table
                                bordered="true"
                                className="regionTable"
                                columns={columns}
                                size={"small"}
                                showHeader={false}
                                pagination={false}
                                dataSource={regionData}
                                expandedRowKeys={this.state.expandedKeys}
                                onExpand={(expanded, record) => {
                                    if (expanded) {
                                        this.setState({ expandedKeys: [...this.state.expandedKeys, record] });
                                    }
                                    else {
                                        let list = [...this.state.expandedKeys];
                                        list.splice(list.indexOf(record), 1);
                                        this.setState({ expandedKeys: list });
                                    }
                                }}
                                rowKey={(record) => {
                                    return record;
                                }}
                                expandable={{
                                    expandedRowRender: (row) => {
                                        let region = row;
                                        data.map(d => {
                                            if (d.name === row) {
                                                if (country && country.length)
                                                    row = getCommonElements(country, d.countries);
                                                else if (siteName.length && this.state.siteNameFilterRegion)
                                                    row = this.state.siteNameFilterRegion.countries;
                                                else
                                                    row = d.countries;
                                            }
                                            return false;
                                        });
                                        let regionCountruList = data.find(item => item.name === region).countries;
                                        return <ErrorGeos expandedKeys={this.state.expandedKeys} row={row.sort().filter(country => regionCountruList.includes(country))} region={region} selectedDate={this.state.selectedDate} filterCountries={countries} filterData={filterData} setAuditDetails={this.setAuditDetails} setColumnName={this.setColumnName} columnData={this.state.columnData} showModal={this.showModal} handleOk={this.handleOk} handleCancel={this.handleCancel} />;
                                    }
                                }}
                                rowExpandable={(record) => {
                                    return true;

                                }}
                            />
                    }

                </Card>

            </React.Fragment >
        );
    }
}
export default ErrorSummaryScreen;