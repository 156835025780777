import '../asset/css/table.css';
import '../asset/css/index.css';
import 'antd/dist/antd.css';

import { Button, Card, DatePicker, Modal, Popover, Select, Table, Tooltip } from 'antd';

import AuditTraceTable from '../components/AuditTraceTable';
import Config from '../util/Config.json';
import DayCountries from "../components/errorDay/ErrorDayCountries";
import { InfoCircleOutlined } from '@ant-design/icons';
import InputComponents from '../components/inputComponents';
import React from 'react';
import js from 'jsonpath';
import jsonDiff from 'json-diff';
import logo from "../asset/images/travelex-logo-old.svg";
import moment from "moment";

// import DayCountries from "../components/DayCountries";





const { Option } = Select;

const style = {
    margin: '10px'
};
class DayScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedDate: moment().format("YYYY-MM-DD"),
            selectedFlags: [],
            flags: Config.FLAGS,
            julianDay: moment().dayOfYear(),
            calenderDateValue: moment(),
            julianDayRange: [],
            isAPICallNeeded: false,
            changeDate: false,
            expandedKeys: [],
            isModalOpen: false,
            selectedCountry: null,
            selectedRegion: null,
            selectedSiteDetails: null,
            statusCode: null
        };

    }

    statusCodesMap = {
        M: { className: "missingTile", description: "Missing" },
        E: { className: "fileProcessingTile", description: "Error in Master" },
        EL: { className: "ledgerErrorTile", description: "Error in Ledger" },
        EW: { className: "warehouseErrorTile", description: "Error in Warehouse" },
        EO: { className: "oracleErrorTile", description: "Error in Oracle" },
        Y: { className: "processingTile", description: "Processed" },
        IP: { className: "inProgressTile", description: "In Progress" },
        C: { className: "closedTile", description: "Closed" },
        EWCN: { className: "warehouseErrorTile", description: "Error in China" },
        EWAU: { className: "warehouseErrorTile", description: "Error in China-APAC" }
    };


    getReqFilterDataProperties(filterData) {
        let { status, region, country, siteName } = filterData;
        return {
            status,
            region,
            country,
            siteName,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevDayFilterData = JSON.parse(localStorage.getItem("DayFilterData"));
        let diff = jsonDiff.diff(this.getReqFilterDataProperties(prevDayFilterData), this.getReqFilterDataProperties(this.props.filterData));
        if (diff) {

            localStorage.setItem("DayFilterData", JSON.stringify(this.props.filterData));
            if (this.state.changeDate) {
                this.setState({
                    isAPICallNeeded: !this.state.isAPICallNeeded,
                    changeDate: false
                });
            }
            else {
                this.setState({
                    isAPICallNeeded: !this.state.isAPICallNeeded,
                    expandedKeys: []
                });
            }

        }
    }
    componentDidMount() {
        localStorage.setItem('DayFilterData', JSON.stringify(this.props.filterData));
        let date = this.state.calenderDateValue;
        let selectedDate = date.format("YYYY-MM-DD");
        if (this.state.changeDate) {
            this.setState({
                julianDayRange: this.getNumberList(),
                selectedDate: selectedDate,
                changeDate: false
            });
        }
        else {
            this.setState({
                julianDayRange: this.getNumberList(),
                selectedDate: selectedDate,
                expandedKeys: []
            });
        }

    }

    getNumberList(date = null) {
        let lastDate = date && date.year() !== moment().year() ? date.isLeapYear() ? 366 : 365 : moment().dayOfYear();

        let numberList = [];
        for (let i = 1; i <= lastDate; i++) {
            numberList.push(i + "");
        }
        return numberList;
    }

    onJulianDayChange = (selectedJulianDay) => {

        let currentStateDate = this.state.calenderDateValue;
        currentStateDate = moment(currentStateDate.dayOfYear(selectedJulianDay));
        this.setState({
            calenderDateValue: currentStateDate,
            julianDay: selectedJulianDay,
            selectedDate: currentStateDate.format("YYYY-MM-DD"),
            isAPICallNeeded: !this.state.isAPICallNeeded,
            changeDate: true
        });
    };

    onCalendarDateChange = (date, dateString) => {
        this.setState({
            calenderDateValue: date,
            julianDay: date.dayOfYear(),
            selectedDate: date.format("YYYY-MM-DD"),
            julianDayRange: this.getNumberList(date),
            isAPICallNeeded: !this.state.isAPICallNeeded,
            changeDate: true
        });
    };

    onRefresh = () => {
        this.setState({
            isAPICallNeeded: !this.state.isAPICallNeeded
        });
    };

    onFlagChange = (event) => {
        const currentFlags = this.props.flags;
        const resetFlags = currentFlags.map(flag => {
            if (event.target.id === flag.flagName) {
                flag.selected = !flag.selected;
            }
            return flag;
        });
        this.props.onUpdateFlags(resetFlags);
    };

    disabledDate = (current) => {

        if (moment().year() < current.year()) {
            return true;
        }
        else if (moment().year() === current.year() && current.month() > moment().month()) {
            return true;
        }
        else if (moment().year() === current.year() && current.month() === moment().month() && current.date() > moment().date()) {
            return true;
        }
        else {
            return false;
        }
    };

    getRegionListForAppliedFilter = (selectedCountries, regions) => {
        let regionList = [];
        for (let regionIndex = 0; regionIndex < regions.length; regionIndex++) {

            for (let countryIndex = 0; countryIndex < selectedCountries.length; countryIndex++) {

                if (regions[regionIndex].countries.includes(selectedCountries[countryIndex])) {
                    regionList.push(regions[regionIndex].name);
                    break;
                }
            }
        }
        return regionList;
    };

    getSiteNameJSONFilterExpression = (selectedSites) => {

        let filterExpression = '$..[?(';
        selectedSites.forEach((site, index) => {
            filterExpression = filterExpression + (index === 0 ? `@.SiteName=="${site}"` : ` || @.SiteName=="${site}"`);
        });

        filterExpression = filterExpression + ')]';
        return filterExpression;
    };

    getCountryAndRegionList = (selectedSites, sidebarResponse) => {

        // get keys 
        let wholeCountryList = Object.keys(sidebarResponse);
        wholeCountryList = wholeCountryList.filter(countryName => countryName !== 'regions');
        let filterExpression = this.getSiteNameJSONFilterExpression(selectedSites);
        let actualCountryList = [];

        for (let i = 0; i < wholeCountryList.length; i++) {

            if (js.query(sidebarResponse[wholeCountryList[i]], filterExpression).length) {
                actualCountryList.push(wholeCountryList[i]);
            }
        }

        // find the exact countries
        let actualRegionList = this.getRegionListForAppliedFilter(actualCountryList, sidebarResponse.regions);
        return {
            actualCountryList,
            actualRegionList
        };


    };

    setAuditDetails = (data, country, region, statusCode) => {
        // console.log("setAuditDetails :: ", data , " ---- ", country, " --- ", this.state.selectedDate, " ==== ", region, " ---- ", statusCode);
        this.setState({ selectedSiteDetails: data, selectedCountry: country, selectedRegion: region, statusCode: statusCode });
        this.showModal();
    };

    showModal = async () => {
        this.setState({ isModalOpen: true });
    };

    handleOk = () => {
        this.setState({ isModalOpen: false, selectedSiteDetails: null });
    };

    handleCancel = () => {
        this.setState({ isModalOpen: false, selectedSiteDetails: null });
    };



    statusInfoModal = () => {

        const getClassName = (dayFlag) => {
            if (dayFlag.startsWith("C")) return "closedTile";
            return this.statusCodesMap[dayFlag]?.className || "";
        };

        const getStatusMessage = (status) => {
            return this.statusCodesMap[status] ? `${status} | ${this.statusCodesMap[status].description}` : "";
        };
        return (
            <Modal title={<img
                alt=""
                src={logo}
                width="160"
                height="50"
                className="d-inline-block align-top"
            />}
                style={{ top: "1%" }}
                // centered
                open={this.state.isModalOpen}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width={"80%"}
                // height={"100%"}
                bodyStyle={{ height: 450 }}
                footer={null}
            >
                <div style={{ height: "100%" }}>
                    <div className='audit-logs-info'>
                        <div style={{ float: "left", width: "50%", paddingLeft: "2px" }}>
                            {/* <b>Site ID : </b>{this.state.auditData ? (this.state.auditData.key): ("")}<br /> */}
                            <b>Site Name : </b>{this.state.selectedSiteDetails ? (this.state.selectedSiteDetails.name) : ("")}<br />
                            <b>File Name : </b>DES{this.state.selectedSiteDetails ? (this.state.selectedSiteDetails.siteId) : ("")}PRD{moment(this.state.selectedDate).dayOfYear()}YR{moment(this.state.selectedDate).format("YY")}.DE<br />
                            <b>Status Message : </b><span className={this.state.statusCode ? (getClassName(this.state.statusCode)) : ("")} style={{ paddingLeft: "5px", paddingRight: "5px" }}>{this.state.statusCode ? (getStatusMessage(this.state.statusCode)) : ("")}</span>
                        </div>
                        <div style={{ float: "left", width: "50%" }}>
                            <b>Date : </b>{this.state.selectedDate}<br />
                            <b>Region : </b>{this.state.selectedRegion}<br />
                            <b>Country : </b>{this.state.selectedCountry}
                        </div>
                    </div>
                    <div>
                        {this.state.selectedSiteDetails ? (
                            <AuditTraceTable country={this.state.selectedCountry} date={this.state.selectedDate} siteId={this.state.selectedSiteDetails.siteId} />
                        ) :
                            ("")
                        }
                    </div>
                </div>


            </Modal>
        );
    };

    cardTitle = () => {
        return (
            <div className="">
                <div style={{ float: "left" }}>Stores</div>

                <div style={{ float: "right" }}>
                    <Button type="primary" loading={false} onClick={this.onRefresh} >
                        Refresh
                    </Button>
                    <Tooltip placement="topLeft" title={"Julian Day"} key={this.state.julianDay}>
                        <Select key={this.state.julianDay}
                            defaultValue={this.state.julianDay}
                            showSearch
                            style={{ width: 100 }}
                            placeholder="Julian Day"
                            optionFilterProp="children"
                            onChange={this.onJulianDayChange}
                            filterOption={(input, option) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                            }
                        >   {
                                this.state.julianDayRange.map(item => <Option value={item} key={item}>{item}</Option>)
                            }
                        </Select>
                    </Tooltip>
                    <DatePicker value={this.state.calenderDateValue} allowClear={false}
                        disabledDate={this.disabledDate}
                        onChange={this.onCalendarDateChange.bind(this)}
                        picker="date" />

                    <Popover
                        content={(
                            <div style={{ maxHeight: '230px', overflowY: 'auto' }}>
                                {
                                    Object.entries(this.statusCodesMap).map(([code, { className, description }]) =>
                                        <p key={code} className={`${className} f16 mb1`}>
                                            <span style={style}>{`${code} | ${description}`}</span>
                                        </p>
                                    )
                                }
                            </div>

                        )}
                        title={<h6>Status Code Sheet</h6>}
                        trigger="hover"
                    >
                        <span> <InfoCircleOutlined /></span>
                    </Popover>
                </div>
            </div>
        );
    };

    render() {
        const columns = [
            { title: 'Region' }
        ];
        let { filterData } = this.props;
        const { region, country, siteName } = filterData;

        let isFilterApplied = Boolean(region?.length || country?.length || siteName?.length);
        let sidebarDataResponse = JSON.parse(localStorage.getItem('sideBarData')).message;
        let regions = sidebarDataResponse.regions;

        let regionNameList = [];
        let selectedCountryList = country;

        if (isFilterApplied && region?.length && (!siteName?.length || (siteName?.length && country?.length))) {
            regionNameList = region;
        }
        else if (isFilterApplied && country?.length && !region?.length) {
            regionNameList = this.getRegionListForAppliedFilter(country, regions);
        } else if (isFilterApplied && !country?.length && siteName?.length) {
            let { actualCountryList, actualRegionList } = this.getCountryAndRegionList(siteName, sidebarDataResponse);
            regionNameList = actualRegionList;
            selectedCountryList = actualCountryList;
        } else {
            regions.map(region => regionNameList.push(region.name));
        }

        return (
            <React.Fragment>
                {/* Modal Section */}
                {
                    this.statusInfoModal()
                }
                

                {/* Checkbox Section */}
                <Card>
                    <InputComponents.Flags flags={this.props.flags} onFlagChange={this.onFlagChange} />
                </Card>
                
                <Card title={this.cardTitle()}>
                    <Table
                        bordered="true"
                        className="regionTable"
                        columns={columns}
                        size={"small"}
                        showHeader={false}
                        pagination={false}
                        dataSource={[].concat(regionNameList)}
                        rowKey={(record) => {
                            return record;
                        }}
                        expandedRowKeys={this.state.expandedKeys}
                        onExpand={(expanded, record) => {
                            if (expanded) {
                                this.setState({ expandedKeys: [...this.state.expandedKeys, record] });
                            }
                            else {
                                let list = [...this.state.expandedKeys];
                                list.splice(list.indexOf(record), 1);
                                this.setState({ expandedKeys: list });
                            }
                        }}
                        expandable={{
                            expandedRowRender: (row) => {
                                let region = row;
                                regions.map(region => {
                                    if (region.name === row) {
                                        row = (isFilterApplied && selectedCountryList?.length) ? selectedCountryList.sort().filter(country => region.countries.includes(country)) : region.countries;
                                    }
                                    return false;
                                }
                                );
                                return <DayCountries expandedKeys={this.state.expandedKeys} filterData={this.props.filterData} selectedFlags={this.props.flags.filter((item) => item.selected)} country={row} region={region} selectedDate={this.state.selectedDate} isFilterApplied={isFilterApplied} isAPICallNeeded={this.state.isAPICallNeeded} setAuditDetails={this.setAuditDetails} />;
                            }
                        }}
                    />
                </Card>
            </React.Fragment>
        );
    }
}
export default DayScreen;